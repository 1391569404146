import moment from "moment-timezone";

export function dateFormat(timestamp) {
  let timestampTmp = Number(timestamp);
  if (isNaN(timestampTmp)) {
    return timestamp;
  }
  let res = moment(new Date(timestampTmp).getTime()).format("yyyy-MM-DD");
  if (isToday(timestamp)) {
    res = res + "(今日不完全数据)";
  }
  return res;
}

export function dateFormat2(timestamp, timezone) {
  let moment1 = moment(new Date(Number(timestamp)).getTime());
  if (timezone) {
    moment1 = moment1.tz(timezone);
  }
  return moment1.format("yyyy-MM-DD");
}
export function dateFormat3(timestamp) {
  return moment(new Date(Number(timestamp)).getTime()).format(
    "yyyy-MM-DD HH:mm:ss"
  );
}

export function isToday(timestamp) {
  let date = new Date();
  let timezoneOffset = date.getTimezoneOffset(); //分钟
  let today = date.setHours(0, 0, 0, 0) - timezoneOffset * 60 * 1000;
  return today === Number(timestamp);
}

export function addDay(date, day) {
  date.setDate(date.getDate() + day);
  return date;
}

// 下划线转换驼峰
export function toHump(name) {
  return name.replace(/_([a-z])/g, (p, m) => m.toUpperCase());
}

export function _urlAddParam(uri, key, value) {
  if (!value) {
    return uri;
  }
  value = encodeURIComponent(value);
  let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  let separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
}

export function urlAddParams(uri, kv) {
  let res = uri;
  for (let k in kv) {
    res = _urlAddParam(res, k, kv[k]);
  }
  return res;
}

/**
 * 计算字符串hash值，需要传入字符串
 */
export function hashcode(str) {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

/**
 * Bigquery直连查询的数据，转化为展示的数据格式
 * @param dataList 来源后台的原始数据
 * @param groupSelected 分组选项
 * @param valueFunction 值函数，用于计算每个单元格的值
 * @param headerSortFunction 表头的排序函数
 * @param headerTextPrefix 表头的额外前缀
 * @param headerTextSuffix 表头的额外后缀
 * @param specialHeaderList 特殊列
 * @param withTotalRow 是否包含汇总行
 * @returns {{headers: *[], values: *[]}}
 */
export function queryDirectConvert(
  dataList,
  groupSelected,
  valueFunction,
  headerSortFunction,
  headerTextPrefix,
  headerTextSuffix,
  specialHeaderList,
  withTotalRow
) {
  let headerSet = new Set();
  let resp = [];
  let totalRowData = { date: "汇总", values: {}, groups: {} };
  for (let data of dataList) {
    resp.push(
      calcDirectCell(
        data,
        groupSelected,
        valueFunction,
        headerTextPrefix,
        headerTextSuffix,
        specialHeaderList,
        headerSet,
        totalRowData
      )
    );
  }
  if (withTotalRow) {
    resp.push(
      calcDirectCell(
        totalRowData,
        groupSelected,
        valueFunction,
        headerTextPrefix,
        headerTextSuffix,
        specialHeaderList,
        headerSet
      )
    );
  }
  let myArr = Array.from(headerSet);
  myArr.sort(headerSortFunction);
  let header = [];
  myArr = myArr.map((a) => {
    let res = a;
    if (headerTextPrefix) {
      res = headerTextPrefix + res;
    }
    if (headerTextSuffix) {
      res = res + headerTextSuffix;
    }
    return res;
  });
  header.push(...myArr);
  return { headers: header, values: resp };
}

function calcDirectCell(
  data,
  groupSelected,
  valueFunction,
  headerTextPrefix,
  headerTextSuffix,
  specialHeaderList,
  headerSet,
  totalRowData
) {
  let obj = { groups: {}, data: {} };
  obj.date = data.date;
  for (let groupSelectedElement of groupSelected) {
    obj.groups[groupSelectedElement.column] =
      data.groups[groupSelectedElement.column];
  }
  obj.player = data.player;
  addData(totalRowData, "player", data.player);
  if (specialHeaderList) {
    for (let specialHeaderListElement of specialHeaderList) {
      obj[specialHeaderListElement] = data[specialHeaderListElement];
      addData(
        totalRowData,
        specialHeaderListElement,
        data[specialHeaderListElement]
      );
    }
  }
  for (let key of Object.keys(data.values)) {
    let header = key;
    if (headerTextPrefix) {
      header = headerTextPrefix + header;
    }
    if (headerTextSuffix) {
      header = header + headerTextSuffix;
    }
    headerSet.add(key);
    if (valueFunction) {
      obj.data[header] = valueFunction(key, data);
    } else {
      obj.data[header] = data.values[key];
    }
    if (totalRowData) {
      addData(totalRowData.values, key, data.values[key]);
    }
  }
  return obj;
}
function addData(table, rowKey, value) {
  if (!table) {
    return;
  }
  if (table[rowKey]) {
    table[rowKey] += value;
  } else {
    table[rowKey] = value;
  }
}

/**
 * Bigquery统计存数据库的数据，转化为展示的数据格式
 */
export function queryDatabaseConvert(
  header,
  dataList,
  groupSelected,
  valueFunction,
  headerTextPrefix,
  headerTextSuffix,
  withTotalRow,
  specialHeaderList
) {
  let resp = [];
  let totalRowData = { date: "汇总" };
  let totalRow = { date: "汇总", player: 0, groups: {}, data: {} };
  for (let data of dataList) {
    let obj = { groups: {}, data: {} };
    obj.date = data.date;
    for (let groupSelectedElement of groupSelected) {
      let columnToHump = toHump(groupSelectedElement.column);
      obj.groups[groupSelectedElement.column] = data[columnToHump];
    }
    obj.player = data.player;
    if (specialHeaderList) {
      for (let specialHeaderListElement of specialHeaderList) {
        obj[specialHeaderListElement] = data[specialHeaderListElement];
      }
    }
    for (let key of header) {
      let header = key;
      if (headerTextPrefix) {
        header = headerTextPrefix + header;
      }
      if (headerTextSuffix) {
        header = header + headerTextSuffix;
      }
      if (valueFunction) {
        obj.data[header] = valueFunction(key, data);
      } else {
        obj.data[header] = data[key];
      }
    }
    if (withTotalRow) {
      totalRow.player += obj.player;
      for (let dataKey in data) {
        if (dataKey !== "date" && typeof data[dataKey] === "number") {
          if (totalRowData[dataKey]) {
            totalRowData[dataKey] += data[dataKey];
          } else {
            totalRowData[dataKey] = data[dataKey];
          }
        }
      }
    }
    resp.push(obj);
  }
  if (withTotalRow) {
    resp.push(totalRow);
    if (specialHeaderList) {
      for (let specialHeaderListElement of specialHeaderList) {
        totalRow[specialHeaderListElement] =
          totalRowData[specialHeaderListElement];
      }
    }
    for (let key of header) {
      let header = key;
      if (headerTextPrefix) {
        header = headerTextPrefix + header;
      }
      if (headerTextSuffix) {
        header = header + headerTextSuffix;
      }
      if (valueFunction) {
        totalRow.data[header] = valueFunction(key, totalRowData);
      } else {
        totalRow.data[header] = totalRowData[key];
      }
    }
  }
  return resp;
}

export const AUTH_TYPE = {
  SUPER_ADMIN: 0,
  ADMIN: 10,
  MARKET: 20,
  PRODUCT: 30,
  GUEST: 40,
};
